import * as Sentry from '@sentry/react';
import type * as React from 'react';
import { RouterProvider } from 'react-aria-components';
import {
  type LinkProps,
  Link as RRLink,
  useHref,
  useNavigate,
} from 'react-router-dom';

export const Link: React.VFC<LinkProps> = ({ to, ...props }) => {
  const path = typeof to === 'string' ? convertHashPath(to) : to;

  return <RRLink to={path} {...props} />;
};

export function convertHashPath(route: string) {
  if (route.startsWith('/')) {
    return route;
  }

  let replacedRoute = route.replace(/^#/, '/');

  if (!replacedRoute.startsWith('/')) {
    replacedRoute = `/${replacedRoute}`;
  }

  if (replacedRoute !== route) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      scope.captureException(
        new Error(`Route ${route} was replaced with ${replacedRoute}`),
      );
    });
  }

  return replacedRoute;
}

export const ClientRouter: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  return (
    // https://github.com/adobe/react-spectrum/issues/7220
    // eslint-disable-next-line react-compiler/react-compiler
    <RouterProvider navigate={navigate} useHref={useHref}>
      {children}
    </RouterProvider>
  );
};
