import Reflux from "reflux";
import _ from "underscore";
import { RoutesActions } from "actions/actions";
import { convertHashPath } from "components/shared/Link";
import { invariant } from "util/invariant";

class RoutesStore extends Reflux.Store {
  listenables = [RoutesActions];

  async navigate_to(route: string) {
    if (!_.isString(route)) {
      return;
    }
    const replacedRoute = convertHashPath(route);

    const { cvpartner } = window;

    invariant(cvpartner, "cvpartner is not initialized");

    await cvpartner.navigate(replacedRoute);
  }

  redirect_to(path: string) {
    window.location.href = path;
  }
}

export const Routes = new RoutesStore();
