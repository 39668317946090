import { action, makeObservable, observable } from 'mobx';
import { http } from 'util/fetch';

class LoggedInClass {
  willBeLoggedOut = false;

  constructor() {
    makeObservable<LoggedInClass, 'setWillBeLoggedOut'>(this, {
      willBeLoggedOut: observable,
      ping: action,
      postponeLogout: action,
      setWillBeLoggedOut: action,
    });
  }

  async ping() {
    try {
      // this does not use our http helper because we don't want to show the spinner
      const res = await fetch('/api/v2/logged_in_status', {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });

      if (res.ok) {
        const loggedInStatus = await res.json();

        const sessionExpiresInMinutes =
          loggedInStatus.session_expires_in_minutes;

        this.setWillBeLoggedOut(
          !sessionExpiresInMinutes || sessionExpiresInMinutes <= 10,
        );
      }
    } catch {
      this.setWillBeLoggedOut(false);
    }
  }

  async postponeLogout(postponedExpiryTime: unknown) {
    try {
      await http.post('/api/v2/logged_in_status', {
        body: {
          logged_in_status: { postponed_expiry_time: postponedExpiryTime },
        },
      });

      this.setWillBeLoggedOut(false);
    } catch {}
  }

  private setWillBeLoggedOut(value: boolean) {
    this.willBeLoggedOut = value;
  }
}

export const LoggedIn = new LoggedInClass();
