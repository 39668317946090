export function invariant<T>(
  value: T | null | undefined,
  message?: string,
): asserts value {
  if (value == null) {
    throw new InvariantError(value, message);
  }
}

export class InvariantError extends TypeError {
  name = 'InvariantError';
  constructor(value: unknown, message?: string) {
    super(message ?? `Assertion failed - value is ${String(value)}`);
  }
}
