import {
  type MetaItem,
  ReactIntegration,
  createReactRouterV6DataOptions,
  defaultMetas,
  getWebInstrumentations,
  initializeFaro,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { matchRoutes } from 'react-router-dom';
import type { Options } from 'types';
import { CVPTraceContextPropagator } from 'util/observability';

export function createFaro(options: Options) {
  const cvpSubdomain: MetaItem = {
    page: {
      url: window.location.href,
      attributes: {
        host: window.location.host,
      },
    },
  };

  const faro = initializeFaro({
    url: '/collect',
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation({
        propagator: new CVPTraceContextPropagator(),
      }),
      new ReactIntegration({
        router: createReactRouterV6DataOptions({
          matchRoutes,
        }),
      }),
    ],
    trackResources: true,
    batching: {
      enabled: true,
      // This is how often we send batches of events to the backend, default is 1s which results in a lot of requests.
      sendTimeout: 5000,
      itemLimit: 10,
      paused: false,
    },
    app: {
      name: 'cvpartner-js',
      version: options.release || 'unknown',
      environment: options.environment || 'unknown',
    },
    sessionTracking: {
      enabled: true,
      persistent: true,
    },
    user: {
      id: options.current_user_id || 'anonymous',
    },
    metas: [...defaultMetas, cvpSubdomain],
  });

  return faro;
}
