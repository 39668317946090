import { mediaQuery } from '@cvpartner/design-system/vars';
import { File06, SearchMd } from '@untitled-ui/icons-react';
import { observer } from 'mobx-react';
import type * as React from 'react';
import { useMediaLayout } from 'use-media';
import { CurrentUser } from 'stores/CurrentUser';
import { I18n } from 'util/translations';
import { AdminNav } from './AdminNav';
import { NavItem } from './NavItem';
import { myCvButton, myCvIcon, myCvLink, nav } from './Navigation.css';
import { UserNav } from './UserNav';

const MyCvButton: React.VFC<{ myCvUrl: string; showLinkText: boolean }> = ({
  myCvUrl,
  showLinkText,
}) => (
  <a href={myCvUrl} className={myCvLink}>
    <div className={myCvButton({ iconOnly: !showLinkText })}>
      <File06 className={myCvIcon} />
      {showLinkText ? I18n.t('My_CV') : null}
    </div>
  </a>
);

const { pathname } = window.location;

const NavigationComponent: React.VFC = () => {
  const showLinkText = useMediaLayout(mediaQuery.s);

  if (!CurrentUser.is_loaded) {
    return null;
  }

  if (
    !CurrentUser.is_limited_access_user &&
    !CurrentUser.is_consultant &&
    !CurrentUser.is_countrymanager &&
    !CurrentUser.is_reference_manager &&
    !CurrentUser.is_internationalmanager
  ) {
    return (
      <nav>
        <UserNav />
      </nav>
    );
  }

  const dashboardUrl = '/dashboard#/persons/proposal/false';
  let myCvUrl = dashboardUrl;

  if (CurrentUser.id && CurrentUser.default_cv_id) {
    myCvUrl += `/cv/${CurrentUser.id}/${CurrentUser.default_cv_id}`;
  }

  return (
    <nav className={nav}>
      <NavItem
        href={dashboardUrl}
        icon={SearchMd}
        linkText={
          showLinkText
            ? I18n.t('application_navigation.company_overview')
            : null
        }
        active={pathname === '/dashboard'}
      />
      <MyCvButton myCvUrl={myCvUrl} showLinkText={showLinkText} />
      <UserNav />
      {CurrentUser.is_internationalmanager || CurrentUser.is_countrymanager ? (
        <AdminNav showLinkText={showLinkText} />
      ) : null}
    </nav>
  );
};

export const Navigation = observer(NavigationComponent);
