// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/lib/subscriber.js

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export class Subscriber {
  constructor(pusher) {
    this.pusher = pusher;
    if (!this.pusher) {
      this.disabled = true;
    }
  }

  session_id() {
    if (!this.pusher) {
      return false;
    }
    return this.pusher.sessionID;
  }

  subscribe(channel_suffix, event, callback, scope) {
    if (this.disabled) {
      return;
    }
    const channel = this.pusher.subscribe(`private-${channel_suffix}`);
    return channel.bind(event, (data) => {
      return callback.call(scope, data);
    });
  }
}
