import * as React from 'react';
import { CurrentUser } from 'stores/CurrentUser';
import { Header as ModernHeader } from './modern';

const { pathname } = window.location;

const isOnLoginPage = pathname === '/login';
const isOnAdminLoginPage = pathname === '/login/admin';
const isOnOtpPage = pathname === '/login/otp';
const isOnLogoutPage = pathname === '/logout';

export const Header: React.VFC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  React.useEffect(() => {
    if (
      isLoggedIn &&
      !isOnLoginPage &&
      !isOnAdminLoginPage &&
      !isOnOtpPage &&
      !isOnLogoutPage
    ) {
      CurrentUser.reload_if_not_loaded();
    }
  }, [isLoggedIn]);

  return <ModernHeader isLoggedIn={isLoggedIn} />;
};
