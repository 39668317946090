import type { Faro } from "@grafana/faro-react";
import * as Sentry from "@sentry/react";
import type { IConfig as UnleashConfig } from "@unleash/proxy-client-react";
import Pusher from "pusher-js";
import type { NavigateOptions, To } from "react-router-dom";
import { Subscriber } from "auto-converted/lib/Subscriber";
import { Pusher as PusherStore } from "auto-converted/stores/Pusher"; // Yes, I know, don't ask...
import { Routes } from "auto-converted/stores/Routes";
import { createFaro } from "metrics";
import type { CVPartnerReactRoute, Options, Router } from "types";
import { getTokenHeaders } from "util/fetch";
import { invariant } from "util/invariant";
import { I18n } from "util/translations";

import "jquery";
import "jquery-ujs";
import "jquery-colorbox";

globalThis.I18n = I18n;

type ReactRoute = new (options: Options) => CVPartnerReactRoute;

export class Cvpartner {
  faro?: Faro;
  subscriber?: Subscriber;
  unleashConfig?: UnleashConfig;
  error?: unknown;
  private router: Router | null = null;
  // This is just a hacky way of making sure the Routes store is initialised as
  // it's not imported anywhere otherwise
  routes = Routes;
  pusher = PusherStore;
  isLoggedIn = false;
  aws_region = "eu-west-1";

  constructor(private readonly reactRoute: ReactRoute) {}

  init(options: Options) {
    this.isLoggedIn = options.current_user_id != null;

    if (options.environment !== "test") {
      try {
        if (options.unleash_client_options) {
          const unleash_url = new URL(
            options.unleash_client_options.path,
            window.location.href,
          );

          unleash_url.port = "443";

          this.unleashConfig = {
            ...options.unleash_client_options,
            url: unleash_url.href,
          };
        }
        const headers = getTokenHeaders();
        const pusher = new Pusher(options.pusher_key, {
          auth: { headers },
          cluster: options.pusher_cluster,
        });
        this.subscriber = new Subscriber(pusher);
        this.faro = createFaro(options);
      } catch (error) {
        this.error = error;
        this.subscriber = new Subscriber(false);
      }

      if (options.sentry_dsn && options.sentry_dsn.trim() !== "") {
        Sentry.init({
          dsn: options.sentry_dsn,
          environment: options.environment || "unknown",
          release: options.release || "unknown",
          beforeSend:
            process.env.NODE_ENV === "development"
              ? (event, hint) => {
                  const message =
                    hint.originalException ??
                    hint.syntheticException ??
                    event.message;

                  if (event.level === "fatal" || event.level === "error") {
                    console.error(message);
                  } else {
                    console.log(message);
                  }

                  return event;
                }
              : undefined,
        });
        // This is useful for debugging
        (window as any).Sentry = Sentry;

        if (this.error) {
          Sentry.captureException(this.error);
        }
      }
    } else {
      this.subscriber = new Subscriber(false);
    }

    if (options.aws_region) {
      this.aws_region = options.aws_region;
    }

    const route = new this.reactRoute(options);

    const router = route.render();

    this.router = router;
  }

  navigate(to: number): Promise<void>;
  navigate(to: To | null, opts?: NavigateOptions): Promise<void>;
  async navigate(to: number | To | null, opts?: NavigateOptions) {
    const { router } = this;

    invariant(router, "Router is not initialized");

    if (typeof to === "number") {
      await router.navigate(to);
    } else {
      await router.navigate(to, opts);
    }
  }
}

declare global {
  interface Window {
    cvpartner?: Cvpartner;
  }
}

// Whenever we start using html history instead of hash history, this needs to
// be removed.
const { location } = window;
if (location.hash[0] === "#" && location.hash[1] !== "/") {
  location.hash = `#/${location.hash.slice(1)}`;
}
