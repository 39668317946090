import type * as React from 'react';
import { useHover } from 'react-aria';
import { anchor, icon } from './NavItem.css';

interface Props {
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  linkText: string | null;
  active: boolean;
}

export const NavItem: React.VFC<Props> = ({
  href,
  icon: Icon,
  linkText,
  active,
}) => {
  const { hoverProps, isHovered } = useHover({});

  return (
    <a
      {...hoverProps}
      href={href}
      className={anchor({ active, iconOnly: linkText == null, isHovered })}
    >
      <Icon className={icon({ active, isHovered })} />
      {linkText}
    </a>
  );
};
