// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/stores/pusher_store.js
import Reflux from "reflux";
import _ from "underscore";
import {
  CollaboratorsActions,
  CustomersActions,
  CvsActions,
  CvsInProposalsActions,
  ExternalCollaborationsActions,
  ProjectsActions,
  ProposalsActions,
  PusherActions,
  ReferenceProjectsActions,
  ReferencesInProposalsActions,
} from "actions/actions";
import { CvMetadatas as CvMetadatasActions } from "stores/CvMetadatas";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Removed namespace declaration
export const Pusher = Reflux.createStore({
  init() {
    this.pusher_channels = [];
    return this.listenToMany(PusherActions);
  },

  subscribe(user_id, cv_id) {
    const channel_suffix = `user_${user_id}_cv_${cv_id}`;
    if (_.contains(this.pusher_channels, channel_suffix)) {
      return;
    }
    this.pusher_channels.push(channel_suffix);
    const handle_pusher_notification = (data) => {
      return this.handle_pusher_notification_for(user_id, cv_id, data);
    };
    return window.cvpartner.subscriber.subscribe(
      channel_suffix,
      "changed",
      handle_pusher_notification,
      this,
    );
  },

  handle_pusher_notification_for(user_id, cv_id, data) {
    CvMetadatasActions.reload_for(user_id, cv_id);
    if (
      data.pusher_session_id ===
        window.cvpartner.subscriber.session_id().toString() &&
      data.force_push !== true
    ) {
      return;
    }
    if (data.action === "updated") {
      this.reload_for(user_id, cv_id);
    }
    if (data.action === "reload_cv_roles") {
      this.reload_cv_roles_for(user_id, cv_id);
    }
    if (data.action === "updated_section") {
      this.reload_section_for(user_id, cv_id, data);
    }
    if (data.action === "updated_subsection") {
      return this.reload_subsection_for(user_id, cv_id, data);
    }
  },

  reload_for(user_id, cv_id) {
    return CvsActions.reload_for(user_id, cv_id);
  },

  reload_cv_roles_for(user_id, cv_id) {
    return CvsActions.reload_cv_roles_for(user_id, cv_id);
  },

  reload_section_for(user_id, cv_id, data) {
    return CvsActions.reload_section_for(
      user_id,
      cv_id,
      data.section_type,
      data.section_id,
    );
  },

  reload_subsection_for(user_id, cv_id, data) {
    return CvsActions.reload_subsection_for(
      user_id,
      cv_id,
      data.section_type,
      data.section_id,
      data.subsection_type,
      data.subsection_id,
    );
  },

  subscribe_company_customer(company_customer_id) {
    const channel_suffix = `company_customer_${company_customer_id}`;
    if (_.contains(this.pusher_channels, channel_suffix)) {
      return;
    }
    this.pusher_channels.push(channel_suffix);
    const handle_pusher_notification = (data) => {
      return this.handle_pusher_notification_for_company_customer(
        company_customer_id,
        data,
      );
    };
    return window.cvpartner.subscriber.subscribe(
      channel_suffix,
      "changed",
      handle_pusher_notification,
      this,
    );
  },

  handle_pusher_notification_for_company_customer(company_customer_id, data) {
    if (
      data.pusher_session_id ===
      window.cvpartner.subscriber.session_id().toString()
    ) {
      return;
    }
    if (data.action === "updated") {
      CustomersActions.reload_customer_for(company_customer_id);
    }
    if (data.action === "created_company_project") {
      ProjectsActions.reload_project_for(
        company_customer_id,
        data.company_project_id,
      );
    }
    if (data.action === "created_company_project") {
      CustomersActions.reload_customer_for(company_customer_id);
    }
    if (data.action === "updated_company_project") {
      ProjectsActions.reload_project_for(
        company_customer_id,
        data.company_project_id,
      );
    }
    if (data.action === "updated_company_project_section") {
      return ProjectsActions.reload_custom_field_for(
        company_customer_id,
        data.company_project_id,
        data.section_type,
        data.section_id,
      );
    }
  },

  subscribe_proposal(proposal_id) {
    const channel_suffix = `proposal_${proposal_id}`;
    if (_.contains(this.pusher_channels, channel_suffix)) {
      return;
    }
    this.pusher_channels.push(channel_suffix);
    const handle_pusher_notification = (data) => {
      return this.handle_pusher_notification_for_proposal(proposal_id, data);
    };
    return window.cvpartner.subscriber.subscribe(
      channel_suffix,
      "changed",
      handle_pusher_notification,
      this,
    );
  },

  handle_pusher_notification_for_proposal(proposal_id, data) {
    if (
      data.pusher_session_id ===
      window.cvpartner.subscriber.session_id().toString()
    ) {
      return;
    }
    if (data.action === "updated") {
      ProposalsActions.reload_proposal(proposal_id);
      CvsInProposalsActions.reload_for(proposal_id);
      ReferencesInProposalsActions.reload_for(proposal_id);
      ExternalCollaborationsActions.reload_for(proposal_id);
      CollaboratorsActions.reload_for(proposal_id);
    }
  },

  subscribe_reference_project(proposal_id, reference_project_id) {
    const channel_suffix = `reference_project_${proposal_id}_${reference_project_id}`;
    if (_.contains(this.pusher_channels, channel_suffix)) {
      return;
    }
    this.pusher_channels.push(channel_suffix);
    const handle_pusher_notification = (data) => {
      return this.handle_pusher_notification_for_reference_project(
        proposal_id,
        reference_project_id,
        data,
      );
    };
    return window.cvpartner.subscriber.subscribe(
      channel_suffix,
      "changed",
      handle_pusher_notification,
      this,
    );
  },

  handle_pusher_notification_for_reference_project(
    proposal_id,
    reference_project_id,
    data,
  ) {
    if (
      data.pusher_session_id ===
      window.cvpartner.subscriber.session_id().toString()
    ) {
      return;
    }
    if (data.action === "updated") {
      ReferenceProjectsActions.reload_for(proposal_id, reference_project_id);
    }
    if (data.action === "updated_reference_project_section") {
      return ReferenceProjectsActions.reload_custom_field_for(
        proposal_id,
        reference_project_id,
        data.section_type,
        data.section_id,
      );
    }
  },

  subscribe_download_file(download_id, callback) {
    const channel_suffix = `download_file_${download_id}`;
    if (_.contains(this.pusher_channels, channel_suffix)) {
      return;
    }
    this.pusher_channels.push(channel_suffix);
    const handle_pusher_notification = (data) => {
      return callback(data);
    };
    return window.cvpartner.subscriber.subscribe(
      channel_suffix,
      "download-ready",
      handle_pusher_notification,
      this,
    );
  },
});
