import { action, autorun, computed, makeObservable, observable } from 'mobx';
import * as React from 'react';

function confirmOnPageExit() {
  return 'Warning: We are currently processing some requests, you may have unsaved changes';
}

class NetworkStateClass {
  private read_queue: ''[] = [];
  private save_queue: ''[] = [];

  constructor() {
    makeObservable<
      NetworkStateClass,
      'read_queue' | 'save_queue' | 'save_in_progress'
    >(this, {
      read_queue: observable,
      save_queue: observable,
      starting_read_request: action,
      starting_save_request: action,
      ended_read_request: action,
      ended_save_request: action,
      failed_save_request: action,
      save_in_progress: computed,
      read_or_save_in_progress: computed,
    });
  }

  starting_read_request() {
    this.read_queue.push('');
  }

  starting_save_request() {
    this.save_queue.push('');
  }

  ended_read_request() {
    this.read_queue.pop();
  }

  ended_save_request() {
    this.save_queue.pop();
  }

  failed_save_request() {
    this.ended_save_request();
    const systemErrorMessages = document.getElementsByClassName(
      'system_errormessage',
    );
    for (const el of Array.from(systemErrorMessages)) {
      el.classList.add('error');
    }
  }

  get save_in_progress() {
    return this.save_queue.length > 0;
  }

  get read_or_save_in_progress() {
    return this.save_in_progress || this.read_queue.length > 0;
  }
}

export const NetworkState = new NetworkStateClass();

export const NavigationBlocker: React.VFC = () => {
  React.useEffect(() => {
    return autorun(() => {
      if (NetworkState.save_in_progress) {
        window.onbeforeunload = confirmOnPageExit;
      } else {
        window.onbeforeunload = null;
      }
    });
  }, []);

  return null;
};
