// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/components/timeout.js.jsx
import React from "react";
import { I18n } from "util/translations";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Removed namespace declaration

export class Timeout extends React.Component {
  state = { postponed_expiry_time: false };

  handle_submit = () => {
    return this.props.continue_callback(this.state.postponed_expiry_time);
  };

  toggle_postponed_expiry_time = () => {
    return this.setState({
      postponed_expiry_time: !this.state.postponed_expiry_time,
    });
  };

  class_names = () => {
    if (this.state.postponed_expiry_time !== true) {
      return "";
    }
    return "selected";
  };

  render() {
    return (
      <div className="modal-wrapper modal-show">
        <div className="popupheading">
          <h1>{I18n.t("soon_logged_out")}</h1>
        </div>
        <div className="modal_content">
          <div className="modal_top_down_padding">
            <div className="input_container">
              <label className="steps left_steps">
                {I18n.t("soon_logged_out_info")}
              </label>
              <ul className="checkbox_list without_margin">
                <li
                  onClick={this.toggle_postponed_expiry_time}
                  className={this.class_names()}
                >
                  <span>{I18n.t("login_checkbox_after_timeout")}</span>
                  <div className="custom_checkbox">
                    <span className="checkmark" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="modal_actions align_wrapper_center">
          <div className="button blue float_right" onClick={this.handle_submit}>
            {I18n.t("close")}
          </div>
        </div>
      </div>
    );
  }
}
