import { observer } from "mobx-react";
import * as React from "react";
import { Timeout as ViewsSharedTimeout } from "auto-converted/views/shared/Timeout";
import { LoggedIn } from "stores/LoggedInStatus";

const TimeoutWatcherComponent: React.VFC = () => {
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      LoggedIn.ping();
    }, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (!LoggedIn.willBeLoggedOut) {
    return null;
  }

  return (
    <div id="timeout_modals">
      <ViewsSharedTimeout
        continue_callback={(value) => LoggedIn.postponeLogout(value)}
      />
    </div>
  );
};

export const TimeoutWatcher = observer(TimeoutWatcherComponent);
