import type { Context, TextMapSetter } from '@opentelemetry/api';
import {
  TRACE_STATE_HEADER,
  W3CTraceContextPropagator,
} from '@opentelemetry/core';

export class CVPTraceContextPropagator extends W3CTraceContextPropagator {
  inject(
    context: Context,
    carrier: unknown,
    setter: TextMapSetter<unknown>,
  ): void {
    setter.set(carrier, TRACE_STATE_HEADER, 'cvp=1');
    super.inject(context, carrier, setter);
  }
}
