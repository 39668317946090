import rebrandText from '@cvpartner/design-system/logos/mainWordmark.svg';
import rebrandLogo from '@cvpartner/design-system/logos/royalBlueIcon.svg';
import { mediaQuery } from '@cvpartner/design-system/vars';
import { observer } from 'mobx-react';
import type * as React from 'react';
import { useProgressBar } from 'react-aria';
import { useMediaLayout } from 'use-media';
import { NetworkState } from 'stores/NetworkState';
import { logo, logoIcon, spinner } from './Logo.css';

const Loader: React.VFC<{ loading: boolean }> = ({ loading }) => {
  const { progressBarProps } = useProgressBar({
    isIndeterminate: true,
    'aria-label': 'Loading',
  });

  return (
    <span
      {...(loading ? progressBarProps : {})}
      className={spinner({ loading })}
    />
  );
};

const LogoComponent: React.VFC = () => {
  const loading = NetworkState.read_or_save_in_progress;
  const showLogoText = useMediaLayout(mediaQuery.m);

  return (
    <a href="/dashboard" className={logo}>
      <img
        className={logoIcon({ loading })}
        src={rebrandLogo}
        alt="Flowcase logo"
        aria-hidden={true}
      />
      <Loader loading={loading} />
      {showLogoText && <img src={rebrandText} alt="Flowcase" />}
    </a>
  );
};

export const Logo = observer(LogoComponent);
