import {
  FaroErrorBoundary,
  withFaroRouterInstrumentation,
} from '@grafana/faro-react';
import { FlagProvider, useFlagsStatus } from '@unleash/proxy-client-react';
import { configure } from 'mobx';
import * as React from 'react';
import ReactDOM from 'react-dom';
import {
  type NavigateOptions,
  type RouteObject,
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from 'react-router-dom';
import { TimeoutWatcher } from 'auto-converted/lib/TimeoutWatcher';
import { Header } from 'components/header';
import { NavigationBlocker } from 'stores/NetworkState';
import type { Router } from 'types';

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: NavigateOptions;
  }
}

const isDevelopment = process.env.NODE_ENV === 'development';

if (isDevelopment) {
  configure({
    enforceActions: 'always',
    computedRequiresReaction: true,
    // this is annoying in that there may be _some_ code paths that don't access
    // observables, while others are. The docs even state it's not an issue to
    // overuse `observer`.
    reactionRequiresObservable: false,
    observableRequiresReaction: true,
    disableErrorBoundaries: true,
  });
}

const NothingUntilUnleashReady: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const status = useFlagsStatus();

  if (status.flagsReady || status.flagsError) {
    return children;
  }

  return null;
};

const headerElement = document.querySelector('header#product-header');

export function renderReactRoot(
  children: React.ReactElement,
  selector: string,
) {
  const element = document.getElementById(selector);
  const loggedIn = window.cvpartner?.isLoggedIn ?? false;
  const unleashConfig = window.cvpartner?.unleashConfig;

  ReactDOM.render(
    <React.StrictMode>
      <FaroErrorBoundary>
        <FlagProvider
          config={unleashConfig}
          startClient={unleashConfig != null}
        >
          <NothingUntilUnleashReady>
            <>
              {headerElement &&
                ReactDOM.createPortal(
                  <Header isLoggedIn={loggedIn} />,
                  headerElement,
                )}
              {loggedIn && <TimeoutWatcher />}
              {children}
            </>
          </NothingUntilUnleashReady>
        </FlagProvider>
        <NavigationBlocker />
      </FaroErrorBoundary>
    </React.StrictMode>,
    element,
  );
}

export function renderRouter(
  routes: RouteObject[],
  selector: string,
  iseBrowserHistoryRouter = false,
): Router {
  const opts: Parameters<typeof createHashRouter>[1] = {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      // we don't use server rendering, so we don't want any hydration. This
      // prints a warning about missing fallbacks.
      v7_partialHydration: false,
    },
  };
  const router = iseBrowserHistoryRouter
    ? createBrowserRouter(routes, opts)
    : createHashRouter(routes, opts);

  const faroRouter = withFaroRouterInstrumentation(router);

  renderReactRoot(
    <RouterProvider
      router={faroRouter}
      future={{ v7_startTransition: true }}
    />,
    selector,
  );

  return faroRouter;
}
