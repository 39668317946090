import { action, makeObservable, observable } from 'mobx';
import { PusherActions } from 'actions/actions';
import type { CvMetadata } from 'schema/cv';
import { http } from 'util/fetch';

class CvMetadatasClass {
  hash = new Map<string, CvMetadata>();
  private loading_hash = new Set<string>();

  constructor() {
    makeObservable<CvMetadatasClass, 'loading_hash' | 'update_cache_for'>(
      this,
      {
        hash: observable,
        update_cache_for: action,
        loading_hash: false,
        reload_for: false,
        key_for: false,
      },
    );
  }

  async reload_for(user_id: string, cv_id: string) {
    const loading_key = this.key_for(user_id, cv_id);
    if (this.loading_hash.has(loading_key)) {
      return;
    }
    this.loading_hash.add(loading_key);
    const url = `/api/v3/cvs/${user_id}/${cv_id}/metadata`;
    try {
      const cv_metadata = await http.get(url);
      this.update_cache_for(loading_key, cv_metadata as CvMetadata);
      this.loading_hash.delete(loading_key);
      return PusherActions.subscribe(user_id, cv_id);
    } catch {
      this.loading_hash.delete(loading_key);
    }
  }

  key_for(user_id: string, cv_id: string) {
    return `${user_id}::${cv_id}`;
  }

  private update_cache_for(key: string, terms: CvMetadata) {
    this.hash.set(key, terms);
  }
}

export const CvMetadatas = new CvMetadatasClass();
