import type * as React from 'react';
import { Logo } from './Logo';
import { Navigation } from './Navigation';
import { container, mainNav } from './index.css';

export const Header: React.VFC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => (
  <div className={mainNav}>
    <div className={container}>
      <Logo />
      {isLoggedIn && <Navigation />}
    </div>
  </div>
);
